<!-- 自动回复页面 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="autoReply-container"
  >
    <div class="hd">
      <div class="name">
        <span>店铺回复</span>
        <!-- <div>
          <el-button
            @click="
              () => {
                openSign();
              }
            "
            type="primary"
            >邀请员工</el-button
          >
          <el-button
            @click="
              () => {
                newaddDialogVisible = true;
              }
            "
            plain
            icon="el-icon-plus"
            class="addBtn"
            >新增账号</el-button
          >
        </div> -->
      </div>
      <div class="gray"></div>
      <!-- 搜索框 -->
      <div class="common-screen-container">
        <div class="common-input-container">
          <span>店铺名称:</span>
          <el-input
            class="common-screen-input"
            v-model="params.shopName"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="common-input-container">
          <span>所属平台:</span>
          <el-select
            class="common-screen-input"
            v-model="params.platformId"
            placeholder="所属平台"
            @change="commonFun"
            clearable
            filterable
          >
            <el-option
              v-for="item in platList"
              :key="item.id"
              :label="item.platformName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="common-input-container">
          <span>所属类目:</span>
          <el-select
            class="common-screen-input"
            v-model="params.categoryId"
            placeholder="所属类目"
            @change="commonFun"
            clearable
            filterable
            remote
            :remote-method="
              (val) => {
                remoteCategory(val);
              }
            "
            @focus="selectClear"
          >
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.platformName"
              :value="item.id"
            >
            </el-option>
            <div class="common-select-page">
              <div
                v-if="categoryParams.pageNum != 1"
                @click="
                  () => {
                    selectPage('up');
                  }
                "
              >
                上一页
              </div>
              <div v-if="categoryParams.pageNum == 1" style="color: #999">
                上一页
              </div>
              <div
                v-if="categoryList && categoryList.length == 10"
                @click="
                  () => {
                    selectPage('down');
                  }
                "
              >
                下一页
              </div>
              <div
                v-if="categoryList && categoryList.length < 10"
                style="color: #999"
              >
                下一页
              </div>
            </div>
          </el-select>
        </div>
        <el-button class="common-screen-btn" type="primary" @click="commonFun"
          >查 询</el-button
        >
        <el-button class="common-screen-btn" @click="() => resetBtn()" plain
          >重 置</el-button
        >
      </div>
    </div>
    <div class="line"></div>

    <div class="padding">
      <!-- 表格区域 -->
      <el-table :data="tableData" class="common-table common-table-bgGrey">
        <el-table-column width="80">
          <template slot-scope="scope">
            <span>
              <el-avatar
                :src="scope.row.shop_logo"
                v-if="scope.row.shop_logo"
              ></el-avatar>
              <span v-if="!scope.row.shop_logo"
                ><i class="el-icon-user common-iconImg-MR"></i
              ></span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="shop_name"
          label="店铺名称"
          :formatter="tableColumn"
        >
        </el-table-column>
        <el-table-column
          prop="platform_name"
          label="所属平台"
          :formatter="tableColumn"
        >
        </el-table-column>
        <el-table-column
          prop="category_name"
          label="所属类目"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="knowledge_num"
          label="知识库数量"
          :formatter="tableColumn"
        >
        </el-table-column>
        <el-table-column
          prop="knowledge_lasttime"
          label="最近维护时间"
          :formatter="tableColumn"
        >
        </el-table-column>
        <el-table-column
          prop="create_name"
          label="创建人"
          :formatter="tableColumn"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          :formatter="tableColumn"
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="() => addDrawer(scope.row)"
              >编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="text-align: center"
        @size-change="(e) => pageFun(e, 'pageSize')"
        @current-change="(e) => pageFun(e)"
        :page-sizes="pagination.pageSizes"
        :page-size="params.pageSize"
        :current-page="params.pageNum"
        layout="total, prev, pager, next,sizes, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用

import { Config, tableColumn } from "../../utils/index.js";
// 类目列表
// import { getPlatList } from "../../service/customer.js";
import { selectCategory, selectPlatform } from "../../service/common.js";
import { knowledgeManage } from "../../service/auto";
import { mapState } from "vuex"

export default {
  name: "autoReply",
  components: {},
  props: {},
  data() {
    return {
      Config,
      tableColumn,
      //   分页
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      loading: false,
      params: {
        pageNum: 1,
        pageSize: 10
      },
      platList: [], // 所属平台
      categoryParams: {
        pageNum: 1,
        pageSize: 10,
        name: ""
      },
      categoryList: [], //类目下拉
      tableData: [] //表格数据
    };
  },
  //监听属性 类似于data概念
  computed: {
     ...mapState(["getUserInfo","comId"])
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.getKnowledgeList()
      },400)
    },
    async selectPlatform() {
      // 平台下拉
      let { data } = await selectPlatform({ pageNum: -1 });
      this.platList = data;
    },
    selectClear() {
      // 下拉清空时触发
      this.categoryParams.pageNum = 1;
      this.categoryParams.name = "";
      this.selectCategory();
    },
    selectPage(type) {
      // 下拉分页
      if (type == "up") {
        this.categoryParams.pageNum--;
      } else {
        this.categoryParams.pageNum++;
      }
      this.selectCategory();
    },
    remoteCategory(val) {
      // 所属类目下拉-筛选
      this.categoryParams.pageNum = 1;
      this.categoryParams.name = val;
      this.selectCategory();
    },
    async selectCategory() {
      // 类目下拉
      let categoryParams = { ...this.categoryParams };
      let { data } = await selectCategory(categoryParams);
      this.categoryList = data.list;
    },
    // 获取知识库列表
    async getKnowledgeList() {
      this.loading = true;
      try {
        let params = { ...this.params };
        const { data: res } = await knowledgeManage(params);
        this.pagination.total = res.total;
        this.params.pageSize = res.size;
        this.tableData = res.records;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    // 分页
    pageFun(e, type) {
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getKnowledgeList();
    },
    // 查询数据
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;

      this.getKnowledgeList();
    },
    // 重置
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        companyId: this.comId
      };
      this.getKnowledgeList();
    },
    // 编辑事件
    addDrawer(row) {
      this.$router.push({
        path: "/library/auto-reply-list/qa-manage",
        query: {
          shopid: row.id
        }
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getComId()
    this.selectPlatform();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.autoReply-container {
  .hd {
    height: 153px;
    .name {
      display: flex;
      justify-content: space-between;
      color: rgba(16, 16, 16, 100);
      font-size: 24px;
      padding: 18px 32px;
      span {
        font-size: 24px;
      }
      .addBtn {
        border-color: #1890ff;
        color: #1890ff;
      }
    }
    .gray {
      height: 1px;
      background-color: rgba(230, 230, 230, 100);
      margin-bottom: 24px;
    }
    .common-screen-container {
      padding-left: 16px;
    }
  }
  .line {
    height: 16px;
    background-color: #ededed;
  }
  .padding{
    padding: 16px 32px;
  }
}
</style>
